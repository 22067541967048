<!-- =========================================================================================
    File Name: ChatNavbar.vue
    Description: Chat Application - Chat navbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div v-if="conversationId != null" class="chat__header">
    <vs-navbar class="p-4 flex navbar-custom shadow" color="white" type="flat">
      <div class="relative flex mr-4">
        <i
          class="mr-2 mt-2 cursor-pointer notranslate material-icons"
          v-if="isSidebarCollapsed"
          @click.stop="$emit('open-contacts-sidebar')"
          >arrow_back</i
        >
        <vs-avatar
          v-if="contact.profilePic !== ''"
          class="m-0 border-2 border-solid border-white"
          size="40px"
          :src="contact.profilePic"
          text-color="#000"
          @click.stop="$emit('show-profile-sidebar', conversationId)"
        />
        <vs-avatar
          v-if="contact.profilePic === ''"
          class="m-0 border-2 border-solid border-white"
          size="40px"
          :text="contactNameInitials"
          :src="contact.profilePic"
          text-color="#000"
          @click.stop="$emit('show-profile-sidebar', conversationId)"
        />
      </div>
      <div class="truncate" :class="{ 'my-1 pt-2': contact.url }">
        <h4 class="text-lg">
          {{ contactName }}
        </h4>

        <a
          id="navbar-url"
          class="navbar-label truncate"
          :href="contact.url"
          target="_blank"
          v-if="contact.url"
        >
          <small>{{ contactUrl }}</small>
        </a>
      </div>
      <vs-spacer></vs-spacer>

      <vs-button
        :key="pinDropdown"
        type="border"
        size="small"
        class="mr-4 p-2 px-3 bg-blac truncate"
        :disabled="contact.closed"
        @click.stop="pin(contact._id, !pinned(contact._id))"
      >
        <div
          class="flex text-primary items-center justify-between w-full rounded rounded-md"
        >
          <div v-if="pinned(contact._id)">
            <Icon icon="tabler:pinned-off" />
            Desafixar
          </div>
          <div v-else>
            <Icon icon="tabler:pin" />
            Fixar
          </div>
        </div>
      </vs-button>

      <postponed-conversation-tag
        v-if="contact.postponedTo"
        :date="contact.postponedTo"
        class="mr-2 p-2 px-3 bg-blac truncate"
        complete
        cancelable
        @onCancel="postponeConversation(null)"
      ></postponed-conversation-tag>

      <vs-dropdown
        v-else
        vs-custom-content
        class="self-center cursor-pointer"
        :disabled="contact.closed"
        vs-trigger-click
      >
        <vs-button
          type="border"
          size="small"
          icon="icon-moon"
          icon-pack="feather"
          class="mr-4 p-2 px-3 bg-blac truncate"
          :disabled="contact.closed"
          data-tooltip="Adiar Conversa"
        >
          Adiar
        </vs-button>
        <vs-dropdown-menu style="width: 270px">
          <p class="pt-1 pb-3 text-center text-lg text-primary">
            Adiar para
          </p>
          <vs-divider class="d-theme-border-grey-light my-0" />

          <div :key="i" v-for="(adiamento, i) in adiamentos">
            <vs-dropdown-item class="p-0">
              <div
                class="flex justify-between p-2 rounded-lg w-full text-black hover:bg-grey-light"
                color="white"
                type="filled"
                @click.stop="
                  adiamento.date
                    ? postponeConversation(adiamento.date)
                    : (activePostponeDatePicker = true)
                "
              >
                <span>{{ adiamento.label }}</span>
                <div v-if="adiamento.date">
                  <span
                    v-if="
                      adiamento.date.dayOfYear() === currentDate.dayOfYear()
                    "
                  >
                    {{ adiamento.date.locale("pt").format("HH:mm") }}
                  </span>
                  <span v-else>
                    {{ adiamento.date.locale("pt").format("ddd") }},
                    {{ adiamento.date.locale("pt").format("HH:mm") }}
                  </span>
                </div>
              </div>
            </vs-dropdown-item>
          </div>
        </vs-dropdown-menu>
      </vs-dropdown>

      <vs-popup :active.sync="activePostponeDatePicker" title="Custom Date">
        <div style="display: flex; justify-content: center">
          <date-picker
            type="datetime"
            inline
            confirm
            :show-second="false"
            @confirm="changeDatePicker"
            v-model="datePickerValue"
          ></date-picker>
        </div>
      </vs-popup>

      <vs-dropdown
        vs-custom-content
        class="self-center cursor-pointer"
        vs-trigger-click
        :disabled="contact.closed"
      >
        <vs-button
          type="border"
          size="small"
          icon="icon-send"
          icon-pack="feather"
          class="mr-4 p-2 px-3 bg-blac truncate"
          :disabled="contact.closed"
          data-tooltip="Atendente"
        >
          <a href="#" class="navbar-label px-1 inline-flex" role="listitem">
            <span class="hidden sm:block mr-1">Atendente:</span>
            {{ getOperatorName(contact.operator) }}
          </a>
        </vs-button>
        <vs-dropdown-menu style="width: 300px">
          <p class="pt-1 pb-3 text-center text-lg text-primary">
            {{ $t("ForwardConversation") }}
          </p>
          <vs-divider class="d-theme-border-grey-light my-0" />
          <div class="flex flex-wrap justify-center p-3">
            <div class="w-full mb-2">
              <small>{{ $t("Intention") }}</small>
              <v-select
                id="department"
                v-model="selectedIntention"
                :clearable="false"
                :options="intentionsOptions"
                name="segment"
              />
            </div>
            <div class="w-full mb-2" v-if="entities.length > 0">
              <small>{{ $t("EntityKey") }}</small>
              <v-select
                v-model="selectedEntity"
                :clearable="true"
                :options="entities"
                name="entities"
              />
            </div>
            <div class="w-full mb-2" v-if="selectedIntention">
              <small>{{ $t("User") }}</small>
              <v-select
                id="user"
                v-model="operator"
                :clearable="true"
                :options="visibleOperators"
                name="segment"
                :no-data-text="$t('NoRecordsFounded')"
                label="name"
              >
                <template v-slot:option="option">
                  <div class="flex items-center">
                    <OnlineCircleComponent
                      :online="option.online"
                      class="mr-3"
                    />
                    {{ option.name }}
                  </div>
                </template>
              </v-select>
            </div>
            <div class="w-full" v-if="selectedIntention">
              <small>Exibir Administradores?</small>
              <vs-switch color="#28c76f" v-model="addAdm" />
            </div>
          </div>
          <vs-dropdown-item class="p-0">
            <vs-button class="w-full" type="filled" @click.stop="setOperator()">
              Encaminhar
            </vs-button>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>

      <vs-tooltip text="Encerrar conversa" position="bottom">
        <vs-button
          v-if="!contact.closed"
          class="w-ful btn-close border-danger"
          color="danger"
          icon-pack="feather"
          icon="icon-x-circle"
          size="small"
          type="filled"
          @click.stop="activeCloseConversationPopup = true"
        />
      </vs-tooltip>
      <vs-button
        v-if="contact.closed"
        type="border"
        size="small"
        icon="icon-download"
        icon-pack="feather"
        class="ml-4 p-2 px-3 bg-blac truncate"
        @click="downloadPDF()"
        id="MainPdfDownloadButton"
      >
        {{ $t("Export") }}
      </vs-button>
    </vs-navbar>

    <div class="pl-6 pt-3 pb-3 flex my-navbar">
      <div class="relative flex justify-start gap-4">
        <span class="truncate-text"
          ><strong>{{ $t("Chatbot") }}: </strong>{{ chatLabel }}</span
        >
        <span class="truncate-text" v-if="entities.length > 0"
          ><strong>{{ $t("EntityKey") }}: </strong>{{ keyEntities }}</span
        >
        <span class="truncate-text"
          ><strong>{{ $t("Intention") }}: </strong>{{ intention }}</span
        >
        <span><strong>ID: </strong>{{ contact.id }}</span>
      </div>
    </div>

    <vs-popup
      :active.sync="activeCloseConversationPopup"
      :title="$t('CloseConversation')"
    >
      <vs-row>
        <vs-col vs-w="6" vs-offset="3">
          <vs-select
            v-model="closingReason"
            :label="$t('SelectClosingReason')"
            width="100%"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in closingConversationReasons"
              :key="index"
              :value="item"
              :text="item.name"
            />
          </vs-select>
        </vs-col>
        <template v-if="closingReason && closingReason.id === 1">
          <vs-col
            vs-w="6"
            vs-offset="3"
            class="mt-4"
            v-for="(item, index) in closingReason.subReasons"
            :key="index"
          >
            <vs-input
              v-if="item.name === 'Valor de venda'"
              v-model.lazy="closingReasonSaleValue"
              v-money="money"
              :label="item.name"
              class="w-full"
            />
            <vs-input
              v-else
              v-model.lazy="saleCustomValue[item.name]"
              :label="item.name"
              class="w-full"
            />
          </vs-col>
        </template>
        <vs-col
          vs-w="6"
          vs-offset="3"
          class="mt-4"
          v-else-if="
            closingReason &&
            closingReason.id !== 1 &&
            closingReason.subReasons &&
            closingReason.subReasons.length > 0
          "
        >
          <vs-select
            v-model="closingSubReason"
            :label="closingReason.name"
            width="100%"
          >
            <vs-select-item
              v-for="(item, index) in closingReason.subReasons"
              :key="index"
              :value="item"
              :text="item.name"
            />
          </vs-select>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-offset="3"
          vs-type="flex"
          vs-justify="center"
          class="mt-4"
        >
          <vs-button
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="acceptCloseChat"
            :disabled="!canClose"
          >
            {{ $t("CloseConversationAccept") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { VMoney } from "v-money";
import PostponedConversationTag from "@/components/PostponedConversationTag.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import OnlineCircleComponent from "@/components/OnlineCircleComponent.vue";
import { differenceInMinutes } from "date-fns";
import ChatPdfTemplate from "./ChatPdfTemplate.vue";
import Vue from "vue"; // Import Vue
import html2pdf from "html2pdf.js";
import { Icon } from "@iconify/vue2";
import { PinConversationHelper } from "../../../helpers/pinConversationHelper";

export default {
  components: {
    Icon,
    "v-select": vSelect,
    PostponedConversationTag,
    DatePicker,
    OnlineCircleComponent,
  },
  props: {
    navbarKey: {
      type: String,
      required: true,
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    },
    intentions: {
      type: Array,
    },
    entities: {
      type: Array,
    },
    closingConversationReasons: {
      type: Array,
      default: () => [],
    },
    chatOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      pinDropdown: 0,
      saleCustomValue: {},
      customSaleReason: "",
      inboxData: null,
      lastFetchTime: null,
      adiamentos: [
        {
          label: "Hoje, mais tarde",
          date: moment().hour(17).minute(0),
        },
        {
          label: "Amanhã",
          date: moment()
            .date(moment().date() + 1)
            .hour(9)
            .minute(0),
        },
        {
          label: "Próxima semana",
          date: moment().add(1, "weeks").startOf("isoWeek").hour(9).minute(0),
        },
        {
          label: "Próximo mês",
          date: moment()
            .add(1, "months")
            .date(1)
            .startOf("isoMonth")
            .hour(9)
            .minute(0),
        },
        {
          label: "Escolher data",
        },
      ],
      datePickerValue: null,
      activePostponeDatePicker: false,
      operator: null,
      selectedIntention: null,
      selectedEntity: null,
      activeCloseConversationPopup: false,
      closingReason: "",
      closingLossReason: "",
      closingReasonSaleValue: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      closingSubReason: "",
      canClose: false,
      addAdm: false,
      fetchedOperators: [],
    };
  },
  directives: { money: VMoney },
  watch: {
    selectedIntention() {
      this.selectedEntity = null;
      this.operator = null;
    },
    selectedEntity() {
      this.operator = null;
    },
    closingReason(val) {
      this.canClose = !(val && val.subReasons && val.subReasons.length > 0);
      this.closingSubReason = "";
    },
    closingSubReason(val) {
      if (val && val.name !== "Valor de venda") {
        this.canClose = true;
      }
    },
    closingReasonSaleValue(val) {
      this.canClose = val !== "R$ 0,00";
    },
    activeCloseConversationPopup() {
      this.saleCustomValue = {};
      this.closingReason = "";
      this.closingSubReason = "";
      this.closingReasonSaleValue = "";
      this.canClose = false;
    },
    conversationId(val, oldVal) {
      this.fetchFilteredOperators();
      if (val !== oldVal) {
        this.operator = null;
        this.selectedIntention = null;
        this.selectedEntity = null;
      }
    },
  },
  computed: {
    currentOperator() {
      return this.$store.state.chat.currentOperator;
    },
    displayedOperators() {
      if (!this.selectedIntention) return [];
      return (
        this.addAdm
          ? this.fetchedOperators
          : this.fetchedOperators.filter(
              (operator) => operator.acl === "operator"
            )
      ).sort((a, b) => {
        if (a.online === b.online) return 0;
        return a.online ? -1 : 1;
      });
    },
    contact: {
      get: function () {
        return this.$store.state.chat.activeContact;
      },
    },
    conversationId: {
      get: function () {
        return this.$store.state.chat.activeConversationId;
      },
    },
    currentDate() {
      return moment().locale("pt_br");
    },
    intentionsOptions() {
      let intentions = JSON.parse(JSON.stringify(this.intentions));
      const index = intentions.findIndex((el) => el === "__all__");
      if (index > -1) intentions.splice(index, 1);
      return [...new Set(intentions)];
    },
    userImg() {
      return this.contact.img;
    },
    contactName() {
      if (!this.contact.name) return this.$t("UnknownName");
      return this.contact.name.length > 16
        ? `${this.contact.name.slice("0", 16)}...`
        : this.contact.name;
    },
    contactUrl() {
      return this.contact.url.length > 30
        ? `${this.contact.url.slice(0, 30)}...`
        : this.contact.url;
    },
    contactNameInitials() {
      var name = this.contact.name || "?";
      var initials = name.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    },
    getStatusColor() {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser);
        if (userStatus === "online") {
          return "success";
        } else if (userStatus === "do not disturb") {
          return "danger";
        } else if (userStatus === "away") {
          return "warning";
        } else {
          return "grey";
        }
      };
    },
    visibleOperators() {
      // This function filters the operators based on the selected intention and entity
      const operatorsList = this.displayedOperators || [];
      if (this.selectedIntention) {
        return operatorsList.filter((operator) => {
          if (!this.selectedEntity) {
            return (
              operator.intentions.includes("__all__") ||
              operator.intentions.includes(this.selectedIntention)
            );
          } else {
            return (
              (operator.intentions.includes("__all__") ||
                operator.intentions.includes(this.selectedIntention)) &&
              (operator.entities.length === 0 ||
                operator.entities.includes(this.selectedEntity))
            );
          }
        });
      }
      return operatorsList;
    },
    intention() {
      if (this.contact.intentions && this.contact.intentions.length)
        return this.contact.intentions[0];
      return this.$t("Others");
    },
    chatLabel() {
      const chatIndex = this.chatOptions.findIndex(
        (el) => el.value === this.contact.chatID
      );
      if (chatIndex > -1) return this.chatOptions[chatIndex].label;
      return "";
    },
    keyEntities() {
      if (this.contact.entities && this.contact.entities.length) {
        return this.contact.entities[0];
      }
      return "-";
    },
  },
  methods: {
    pinned(conversationId) {
      return PinConversationHelper.pinned(
        conversationId,
        this.currentOperator._id
      );
    },
    pin(conversationId, pinned) {
      this.pinDropdown++;
      if (pinned) {
        if (
          !PinConversationHelper.pin(conversationId, this.currentOperator._id)
        ) {
          this.$vs.notify({
            time: 5000,
            title: this.$t("Error"),
            text: "O limite máximo de 3 conversas fixadas foi alcançado.  ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
          return;
        }
      } else {
        PinConversationHelper.unpin(conversationId, this.currentOperator._id);
      }

      this.$emit("pin-conversation", { conversationId, pinned });
    },
    isOnline(time) {
      if (!time) return null;
      let date_time_to = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) date_time_to = new Date(time);
      let date_time_from = new Date();
      let minutes = differenceInMinutes(date_time_from, date_time_to);
      return minutes <= 10;
    },
    changeDatePicker(date) {
      this.activePostponeDatePicker = false;
      this.datePickerValue = null;
      this.postponeConversation(date);
    },
    postponeConversation(date) {
      window.analytics.track(
        "Adiar Conversa",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$emit("postpone-conversation", { postponedTo: date });
    },
    getUserStatus(isActiveUser) {
      return isActiveUser ? this.$store.state.user.status : this.contact.status;
    },
    getOperatorName(operadorId) {
      const storedOperators = this.$store.state.chat.inboxOperatorsArrayStored;
      const currentOperator = storedOperators.find(
        (operator) => operadorId && operator.value === operadorId
      );
      if (currentOperator) {
        const operatorName = currentOperator.label.trim().split(" ");
        const formatedOperatorName =
          operatorName[0].length > 11
            ? `${operatorName[0].slice(0, 11)}`
            : operatorName[0];

        return currentOperator.label !== "" ? formatedOperatorName : "";
      } else return "--";
    },
    setOperator() {
      if (!this.selectedIntention) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("FormValidationGeneric"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      } else {
        window.analytics.track(
          "Transferir Conversa",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
        this.$emit(
          "set-operator",
          this.operator ? this.operator._id : null, // Gets the operator ID from cb_inbox_operator
          this.selectedIntention,
          this.selectedEntity
        );
      }
    },
    acceptCloseChat() {
      if (!this.closingReason) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: "Preencha todos os campos corretamente",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return;
      }

      let finalClosingReason = JSON.parse(JSON.stringify(this.closingReason));

      if (finalClosingReason.subReasons.length > 0) {
        finalClosingReason.subReasons = this.closingSubReason;

        if (this.closingReasonSaleValue)
          finalClosingReason.closingReasonSaleValue =
            this.closingReasonSaleValue;
      }
      if (this.saleCustomValue)
        finalClosingReason.saleCustomValue = this.saleCustomValue;

      this.$emit("close-conversation", finalClosingReason);
    },

    downloadPDF() {
      this.$vs.loading({
        container: "#MainPdfDownloadButton",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#MainPdfDownloadButton > .con-vs-loading");
      }, 3000);
      try {
        const ChatPdfConstructor = Vue.extend(ChatPdfTemplate);
        const fileName = `${this.contact.name.split(" ")[0]}-${
          this.contact._id
        }.pdf`;
        const chatInstance = new ChatPdfConstructor({
          store: this.$store,
          propsData: {
            contact: this.contact,
            API_URL: process.env.VUE_APP_API_URL,
          },
        }).$mount(); // Mount it in memory

        // Get the rendered HTML of the component
        const htmlContent = chatInstance.$el;

        // Use html2pdf to generate and download the PDF
        const options = {
          margins: [5, 5, 5, 5],
          filename: fileName,
          image: { type: "jpeg", quality: 0.8 },
          html2canvas: { scale: 1.5 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: "avoid-all" },
        };

        html2pdf().from(htmlContent).set(options).save();
        this.$vs.loading.close("#MainPdfDownloadButton > .con-vs-loading");
      } catch (error) {
        this.$vs.loading.close("#MainPdfDownloadButton > .con-vs-loading");
      }
    },
    async fetchFilteredOperators() {
      const now = Date.now();
      // 2min time out to avoid multiple requests
      if (this.lastFetchTime && now - this.lastFetchTime < 120000) {
        return;
      }
      this.lastFetchTime = now;

      const params = { acc: this.$store.state.acc.current_acc.id };
      try {
        const response = await this.$http.get(
          "/p/chat/inbox/operators/filter",
          {
            params,
            headers: {
              Authorization: "Bearer " + this.$store.state.auth.accessToken,
              "Content-Type": "Application/json",
            },
          }
        );
        this.fetchedOperators = response.data.data;
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    this.fetchFilteredOperators();
  },
};
</script>
<style scoped lang="scss">
.btn-close {
  padding: 15px !important;
}
#icon-status > span > .con-vs-avatar {
  background-color: inherit !important;
}
#navbar-url {
  width: 200px;
  margin-top: -5px;
}
/*#navbar-operator {
  padding-right: 10px; width:150px;
}*/
.navbar-label {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.truncate-text {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input:focus {
  border: 1px solid rgba(var(--vs-primary), 1) !important;
}

.my-navbar {
  border-bottom: 2px solid #d3d3d3;
  justify-content: flex-start;
  background-color: rgba(var(--vs-primary), 0.2);
}
</style>
